import React from 'react';
import { TICKET_FEE } from 'src/constants';

export default {
  id: 'EN',
  yes: 'Yes',
  no: 'No',
  ok: 'OK',
  for: 'for',
  in: 'in',
  cancel: 'Cancel',
  delete: 'Delete',
  close: 'Close',
  verify: 'Verify',
  send: 'Send',
  city: 'City',
  address: 'Address',
  optional: 'Optional',
  description: 'Description',
  verification: 'Verification',
  tabs_fangouts: 'Fangout requests',
  tabs_posts: 'Posts',
  tabs_requests: 'Requests',
  tabs_profile: 'Profile',
  tabs_account: 'Account',
  tabs_users: 'Users',
  fangouts_search: 'Search',
  fangouts_bill: 'Bill',
  fangouts_fans: 'Fans',
  fangouts_type: 'Type',
  fangouts_time: 'Time',
  fangouts_complete: 'Confirm',
  CASUAL: 'Casual',
  casual_description: '(Drink, dinner, walk, tennis match...)',
  EDUCATION: 'Education',
  education_description: '(Knowledge transfer of any kind)',
  PERFORMANCE: 'Performance/Service',
  performance_description: '(Singing, acting, dancing, standup...)',
  FAN_PAYS: 'Fan Pays',
  SPLIT: 'Split',
  CELEBRITY_PAYS: 'Celebrity Pays',
  bill: 'the bill',
  EUR: '€',
  USD: '$',
  most_popular: 'Most popular',
  most_active: 'Most active',
  latest_fangouts: 'Latest Fangouts',
  unavailable_atm: 'User is not available',
  requests_title: 'Requests',
  under_construction: 'Under construction...',
  profile_title: 'Profile',
  profile_status: 'Status',
  profile_share_refferal: 'Invite a friend and earn (click to share)',
  profile_settings: 'Settings',
  profile_personal_details: 'Profile',
  profile_payment_history: 'Payment History',
  profile_referrals: 'Referrals',
  profile_logout: 'Logout',
  profile_remove_account: 'Remove account',
  profile_remove_account_message1: 'Sure to delete your account?',
  profile_remove_account_message2: 'This process is irreversible.',
  profile_help_title: 'Why to verify?',
  profile_help_message1:
    "In order to ensure high level of security it's necessary to verify using personal id or passport.",
  profile_help_message2:
    'You can be calm and safe, after verification we store only document number, photo will be deleted permanently.',
  profile_help_ref_title: 'Refer a friend',
  profile_help_ref_message1:
    'Refer your connections to Bilet.rs and earn 3% on all of their sales, and 2% of all their referrals earnings.',
  profile_help_ref_message2:
    "Your award of 3% and 2% is coming from the platform and doesn't affect your friend's earnings.",
  increase_credibility: 'Adding social networks increases your credibility',
  settings_title: 'Settings',
  settings_enable_light_mode: 'Enable light mode',
  settings_enable_dark_mode: 'Dark mode',
  settings_language: 'Language',
  settings_currency: 'Currency',
  referrals_title: 'Refferals',
  createFangout_title: 'New Fangout',
  editFangout_title: 'Edit Fangout',
  requestFangout_title: 'Request Fangout',
  requestFangout_user_is_not_available:
    'User is not available at that date or location',
  details_title: 'Details',
  payment_title: 'Payment',
  label_title: 'Name of the event',
  label_location: 'Location',
  label_date: 'Start date',
  label_end_date: 'End date',
  label_time: 'Time',
  label_type: 'Type',
  label_bill: 'Bill',
  label_audience: 'Fans/Audience',
  label_price: 'Fangout Price',
  label_set_price: 'Set Price',
  label_request: 'Request',
  label_create: 'Create',
  label_edit: 'Edit',
  label_save: 'Save',
  label_from: 'From',
  label_to: 'To',
  label_celebrity: 'Celebrity',
  label_my_fangouts: 'My Fangouts',
  label_duration: 'Duration',
  label_profession: 'Profession',
  label_not_visible_to_others: '(visible only by admin)',
  label_full_name: 'Full name',
  label_name: 'Name',
  label_surname: 'Surname',
  label_email: 'Email',
  label_stage_name: 'Stage name',
  label_bio: 'Bio',
  label_soc_networks: 'Social networks',
  bank_info: 'Add bank account (to earn)',
  label_bank_country: 'Bank country',
  label_bank_name: 'Bank name',
  label_bank_account: 'Bank account',
  attention_user_is_not_verified: '(Attention, user is not verified)',
  requests_received: 'Received',
  requests_sent: 'Sent',
  requests_history: 'Completed',
  requests_confirm_title: 'Confirm',
  requests_confirm_complete_content: (
    <div className={'content'}>
      Complete means that service has been provided to you. If yes you can{' '}
      <div>
        <span className={'text-primary text-bold'}>Complete</span> the Fangout?
      </div>
    </div>
  ),
  requests_confirm_content: function (approve) {
    return (
      <div className={'content'}>
        Are you sure you want to{' '}
        {approve === 0 ? (
          <div>
            <span className={'text-primary text-bold'}>Approve</span> this
            request?
          </div>
        ) : (
          <div>
            <span className={'text-danger text-bold'}>Dismiss</span> this
            request?
          </div>
        )}
      </div>
    );
  },
  requests_dispute_help_title: 'Dispute',
  requests_dispute_help_content:
    'If service is not fully provided or it is not provided at all you can start the dispute. Admin will contact you soon in order to solve the issue.',
  requests_dispute_submitAction: 'Dispute',
  requests_dispute_content:
    'If you want to initiate a dispute please describe what happened (in 2000 characters).',
  requests_dispute_confirmation: 'Thank you. Someone will contact you soon.',
  requests_dispute_notification:
    'Dispute. Administrator will contact you soon.',
  has_set_the_price: 'has set the Fangout price',
  price_is_set_note:
    'Price is set successfully, please wait for Fan to pay or cancel the request.',
  pay: 'Pay',
  message_placeholder: 'Send message',
  message_noConversation: 'Specify details via messages',
  click_to_verify: 'click to verify',
  create_post: 'Create Post',
  new_post_title: 'New Post',
  post_caption: 'Write a caption',
  upload: 'Upload',
  save: 'Save',
  toggle_push_notifications: 'Push notifications',
  fan_has_paid: 'Paid',
  remove: 'Remove',
  sent: 'Sent',
  help_new_fangout1:
    'Fangout is an event that connect Celebrity with Fan(s) on a certain way.',
  help_new_fangout2: 'It can be a professional, educational or casual type.',
  help_new_fangout3:
    'Fangout can be created by anyone and it is free of charge. Number of created Fagnouts is unlimited',
  help_new_fangout4:
    'During creation there are some details like place, time, duration and price but, further details users can arrange via chat messages on the application. ',
  help_new_fangout5:
    "After creating the Fangout event it's recommended to share it with Fans so they can buy it asap.",
  help_new_fangout6:
    "When setting the price of a Fangout please have in mind platform's commission of 20%.",
  support: 'Support',
  deleted: 'Deleted',
  verify_message1:
    'For security reasons, in order to use all options on the platform, all users has to be verified.',
  verify_message2:
    'To verify your Profile please upload your Personal ID or Passport scan.',
  profile_unverified: 'Profile UNVERIFIED',
  verify_first:
    'For security reasons, only verified users can fully use the platform. Please verify first.',
  verify_be_patient:
    'Your profile will be verified soon. Thank you for your patient.',
  reset_pass_title: 'New password',
  reset_pass_summary: 'Please, enter yoru new password.',
  reset_pass_new_password: 'New password',
  reset_pass_repeat_password: 'Repeat password',
  forget_pass_heading: 'Forgot Password',
  forget_pass_summary:
    'Please, enter your email address and we will send you instructions for creating your new password.',
  forget_pass_title: 'Check your email',
  forget_pass_message:
    'We have sent a password recovery instruction to your email.',
  publicProfile_subscribe: 'Follow',
  publicProfile_subscribed: 'Following',
  publicProfile_subscribe_title: 'Fallow the user',
  publicProfile_subscribe_help:
    'Get informed when user is available or create a Fangout event.',
  publicProfile_unsubscribe: 'Unfollow',
  subscriptions_title: 'Following',
  subscriptions_title_help: 'Users and locations you are following to.',
  availability_title: 'Availability',
  availability_isAvailable: 'Available for Fangout requests',
  availability_newAvailability: 'Add new availability period',
  availability_help:
    'If switch is ON and no specific date ranges added below, it means that you are available at any time.',
  user_availability: 'User availability',
  myagent_title: 'Fangout Agent',
  myagent_on: 'Activated',
  myagent_off: 'Inactive',
  myagent_title_short: 'F-Agent',
  myagent_title_help:
    'Set up Fangout Agent that will set the price automatically on specific Fangout request type.',
  myagent_help_performance:
    'You can define max number of audience and charge upon duration of the engagement.',
  myagent_help_education:
    'You can define max number of audience on the class and charge per 1 hour.',
  myagent_help_casual:
    'You can define max number of audience, max duration and charge for fist and every next hour.',
  myagent_help_additinal:
    " If Fangout request doesn't fit into any of your rules price will not be set automatically and you will be able to set it manually.",
  myagent_h: 'hour',
  myagent_h_n: 'th',
  myagent_h_first: 'st',
  myagent_h_second: 'nd',
  myagent_h_third: 'rd',
  myagent_add_next_hour: 'Set price for next hour',
  myagent_h_evry_next: 'Every next hour',
  myagent_max_audience: 'Max audience',
  myagent_max_duration: 'Max duration',
  myagent_h_per_person: '1h/person',
  fout_autocompletion_label1: 'Auto-confirm in',
  fout_autocompletion_label2: 'Fangout will be auto-confirmed now.',
  confirm_are_you_sure: 'Are you sure?',
  premium_feature: 'Premium feature',
  premium_buy_year: 'Buy Premium plan',
  premium_activate: 'Activate',
  premium_type_code: 'Use activation code',
  premium_desc_availability: (
    <div>
      <b>Availability</b> lets you set dates and places when you are available.{' '}
      <br />
      <br />
      This will restrict users to send you Fangout requests only for places and
      at time you are available at.
    </div>
  ),
  premium_desc_myagent: (
    <div>
      <b>F-Agent</b> is your agent and will set the price to the received
      Fangout requests immediately, if they fit to your F-Agent setup. <br />
      <br />
      This will save you negotiation time. Setup the F-Agent, get back and let
      him works for you.
    </div>
  ),
  switch_to_professional: 'Switch to Professional Account',
  switch_to_regular: 'Switch to Regular Account',
  premium_expires: 'Premium active to: ',
  premium_buy_description:
    'Premium enables two additional options which saves time and increase your efficiency dramatically',
  buy: 'Buy',
  premium_label_months: 'Premium duration',
  premium_label_months_desc: '(months)',
  premium_price_1: 'Price: 50',
  premium_price_2: '/month',
  premium_price_discount_1: '(for 12+ months 40',
  premium_price_discount_2: 'monthly)',
  premium_to_pay: 'To pay:',
  premium_months: 'month(s)',
  premium_payment: 'Premium payment',
  event: 'event',
  SHOW: 'Event',
  ONLINE_SHOW: 'Online event',
  HYBRID_SHOW: 'Hybrid event',
  show_desc: 'Regular live event. Only tickets for the event are available.',
  online_desc:
    'Online only event. Only online streaming tickets are available.',
  hybrid_desc:
    'Live event + online streaming. Both, the live event and online tickets are available.',
  show_budget: 'Budget',
  show_budget_desc:
    'Necessary cost of the event (artists, place, transport etc.)',
  show_confirmation_point: 'Confirmation point',
  show_confirmation_point_desc:
    'This is a sum that includes the Budget + 6% Fangout fee.\n' +
    'If the confirmation point is not reached, all buyers\n' +
    'automatically get refund and event is get canceled.',
  show_funding_until: 'Funding until',
  show_funding_until_desc:
    'If the Budget is not reached up to this date event will \n' +
    'be canceled and funds will be refunded.',
  show_image: 'Event poster',
  show_upload_image: 'Upload image',
  show_event_image_size: '(1200x1600 recommended)',
  show_tickets: 'Ticket types',
  show_streaming_ticket: 'Streaming ticket',
  show_streaming_ticket_price: 'Streaming ticket price',
  show_streaming_ticket_desc: 'Streaming ticket description',
  show_ticket_title: 'Ticket type title',
  show_ticket_desc: 'Ticket type description',
  show_tickets_number: 'Num. of tickets',
  show_tickets_price: 'Price per ticket',
  show_add_ticket: 'Add ticket type',
  show_count_profit: `Your profit (goal - ${TICKET_FEE}% platform fee)`,
  show_count_total_tickets: 'Total tickets',
  show_count_funding_goal: 'Funding goal',
  show_age_restriction: 'Age restriction',
  show_no_restriction: 'No restriction',
  show_is_private: 'Private event',
  show_private_event: 'Access via URL only',
  show_publish: 'Publish',
  show_publish_message: (
    <div>
      Are you sure you want to publish this Event.
      <br /> Check the settings again, future edit will be limited for some
      fields.
    </div>
  ),
  show_delete_desc: 'Are you sure?',
  show_ticket_soldout: 'Sold out',
  show_sold: 'Sold',
  show_earned: 'Earned',
  ticket: 'Ticket',
  tickets: 'Tickets',
  label_shows: 'Events',
  label_my_shows: 'My Events',
  more: 'More',
  less: 'Less',
  invalidator: 'Validator',
  invalidated: 'Invalidated',
  invalidate: 'Invalidate',
  reporter: 'Reporter',
  reported: 'Reported',
  report: 'Sold',
  invalidation_success: 'Invalidated successfully!',
  default_qr_scanner_message: 'No result',
  share_the_invalidator: 'Share the Validator',
  share_the_reporter: 'Share the repoter',
  show_go_to_public_page: 'Go to public page',
  statistics: 'Statistics',
  total: 'Total',
  ticket_type: 'Ticket type',
  purchased: 'Purchased',
  need_login_show_message: 'To buy tickets please login first.',
  ticket_payment: 'Tickets payment',
  ticket_payment_ticketsTotal: 'Tickets total',
  ticket_payment_discount: 'discount',
  ticket_payment_bankFee: 'bank fee',
  ticket_order: 'Order list',
  show_cannot_cancel:
    'NOTE: If event starts within next 5 days, once published it can not be canceled.',
  no_streaming: 'No streaming.',
  no_tickets: 'No tickets found',
  no_requests: 'No received requests.',
  no_requests_sent: 'No sent requests.',
  no_requests_history: 'No history.',
  no_following: 'No following anyone.',
  no_following_locations: 'Add the location to be notified about any change.',
  or: 'or',
  qty: 'qty',
  checkout: 'Checkout',
  welcome_note: 'New way to connect with fans.',
  register: 'Register',
  welcome_has_account: 'Already have an account?',
  login: 'Login',
  login_dont_have_account: 'Don’t have an account?',
  register_acceptance: "By registering you are accepting platform's",
  register_terms_of_use: 'Terms of use',
  label_public: 'Public',
  label_private: 'Private',
  label_username: 'Username',
  label_password: 'Password',
  label_verify_password: 'Verify Password',
  registration: 'Registration',
  professional_acc: 'Professional account',
  register_to_earn_money: 'To earn money',
  continue: 'Continue',
  show_allowed_invalidations: 'Number of allowed entrances',
  show_allowed_invalidations_help_title: 'Allowed entrances',
  show_allowed_invalidations_help_message:
    'When ticket is meaned to be used multiple days `Allowed entrances` has to be set for number of days we want to allow users to enter the event.',
  ticketInvalidatedInLast12Hrs:
    'Ticket has been already invalidated in last 12 hours',
  tickets_price: 'Tickets price',
  live: 'Live',
  is_draft: 'Is draft',
  user_form_title: 'User details',
  tax_included: 'Tax included into price',
  contact: 'Contact',
  ticket_shipment:
    'Your tickets will be sent to you via email, and they will also be available in your wallet on the platform https://app.bilet.rs',
};
