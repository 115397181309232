import { useHistory } from "react-router-dom";
import { useSelector, connect } from "react-redux";
import styled from "styled-components";

export const AddFangout = () => (
  <AddButton>
    <i className={"icon-add"} />
    <i className={"icon-logo_glass"} />
  </AddButton>
);

const Tabs = (props) => {
  const history = useHistory();
  const userData = useSelector((state) => state.profile.userData);

  const goTo = (route) => {
    history.push(route);
  };

  const activeTab = props.active !== undefined ? props.active : 0;

  return (
    <TabsContainer style={props.style}>
      <Tab onClick={() => goTo("/home")}>
        <Icon className={"icon-home"} active={activeTab === 0} />
        {/*<TabName active={activeTab === 0}>{props.lang.tabs_fangouts}</TabName>*/}
      </Tab>
      {/* <Tab onClick={() => goTo('/requests')}>
        <Icon className={'icon-logo_glass'} active={activeTab === 2} />
      </Tab> */}
      <Tab onClick={() => goTo("/mytickets")}>
        <Icon className={"fas fa-ticket"} active={activeTab === 3} />
        {/*<TabName active={activeTab === 2}>{props.lang.tabs_requests}</TabName>*/}
      </Tab>
      <Tab onClick={() => goTo("/profile")}>
        {userData.profilePhoto ? (
          <img
            className={`profilePhoto ${activeTab === 4 ? "active" : ""}`}
            src={userData.profilePhoto}
            alt={"user_photo"}
          />
        ) : (
          <Icon className={"icon-profile"} active={activeTab === 4} />
        )}
        {/*<TabName active={activeTab === 3}>{props.lang.tabs_account}</TabName>*/}
      </Tab>
    </TabsContainer>
  );
};

function mapStateToProps(state) {
  return {
    lang: state.home.language,
  };
}

export default connect(mapStateToProps)(Tabs);

const TabsContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 600px;
  height: 50px;
  // height: 60px;
  justify-content: space-around;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.tabsBackgroundColor};
  backdrop-filter: blur(8px);
  // background: ${({ theme }) => theme.background}
`;

const Tab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  font-size: 12px;
  padding: 0 13px;
  border-radius: 30px;
  width: 60px;
  box-sizing: border-box;

  &:active,
  &:focus {
    background-color: rgba(255, 255, 255, 0.2);
  }
  img.profilePhoto {
    width: 34px;
    height: 34px;
    border-radius: 36px;
    padding: 2px;
    &.active {
      padding: 0;
      border: 2px solid ${({ theme }) => theme.primary};
    }
  }
`;

const Icon = styled.div`
  color: ${(props) => (props.active ? props.theme.primary : props.theme.text)};
  font-size: 24px;
  height: 28px;
`;

const TabName = styled.div`
  color: ${(props) => (props.active ? props.theme.primary : props.theme.text)};
`;
export const Fab = styled.div`
  position: absolute;
  bottom: 80px;
  right: 20px;
  font-size: 18px;
  color: #000;
  padding: 12px;
  line-height: 0;
  border-radius: 100%;
  background: ${({ theme }) => theme.primary};
  cursor: pointer;

  .menu {
    position: absolute;
    top: -80px;
    right: 10px;
    background: ${({ theme }) => theme.primary};
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: 6px 12px;

    .item1 {
      line-height: 2;
      font-size: 16px;

      &:active {
        font-size: 18px;
      }

      transition: all 0.4s ease;
    }

    .item2 {
      line-height: 2;
      font-size: 16px;

      &:active {
        font-size: 18px;
      }

      transition: all 0.4s ease;
    }
  }
`;

export const AddButton = styled.div`
  position: relative;
  height: 20px;
  width: 20px;

  i.icon-logo_glass {
    font-size: 20px;
    position: absolute;
    top: 1px;
    left: 4px;
  }

  &.show {
    i.fa-guitar {
      font-size: 16px;
      position: absolute;
      top: 4px;
      left: 6px;
    }

    i.icon-add {
      font-size: 10px;
      position: absolute;
      top: 1px;
      left: 0px;
    }
  }

  i.icon-add {
    font-size: 12px;
    position: absolute;
    top: 5px;
    left: -5px;
  }
`;
