/*
Warm colour set
*/
const warm = {
  primary: "#F29F58",
  secondary: "#AB4459",
  lightSecondary: "#fff3ea",
  dark: "#441752",
  darkest: "#1B1833",
};

export const darkTheme = {
  // primary: "#00fff0",
  // primary: '#00cebd',
  primary: warm.primary,
  background: warm.darkest,
  secondBackground: warm.dark,
  contastBackground: "#f8f8f8",
  selectBackground: "#f8f8f8",
  selectOptionBgrSelected: "#d5edeb",
  selectOptionBgrFocused: "#e3f9f6",
  selectOptionText: "#ccc",
  selectOptionTextSelected: "#000",
  body: "#000",
  text: "#ffffff",
  buttonText: "#000",
  contrastText: "#000000",
  dangerBackground: "#301d28",
  red: "#ff0058",
  yellow: "#ffb100",
  gradient: "linear-gradient(#39598A, #79D7ED)",
  fontFamily: "AvenirNext",
  gray: "#757575",
  middleGray: "#808080",
  lightGray: "#ccc",
  darkGreen: "#182023",
  secondaryButtonColor: warm.secondary,
  secondaryButtonBackground: warm.dark,

  // Semantic colors
  uploadImageBorder: "#384950",
  tabsBackgroundColor: "rgb(31 33 35/.2)",
};

export const lightTheme = {
  // primary: "#00cebd",
  primary: warm.primary,
  secondary: warm.secondary,
  background: "#fff",
  secondBackground: warm.lightSecondary,
  contastBackground: "#333",
  selectBackground: "#f8f8f8",
  body: "#fff",
  text: warm.darkest,
  buttonText: warm.darkest,
  contrastText: warm.dark,
  dangerBackground: "#301d28",
  red: "#ff0058",
  yellow: "#ffb100",
  gradient: "linear-gradient(#39598A, #79D7ED)",
  fontFamily: "AvenirNext",
  gray: "#757575",
  selectOptionBgrSelected: "#d5edeb",
  selectOptionBgrFocused: "#e3f9f6",
  selectOptionText: "#ccc",
  selectOptionTextSelected: "#000",
  middleGray: "#808080",
  lightGray: "#ccc",
  darkGreen: "#182023",
  secondaryButtonColor: "#fff",
  secondaryButtonBackground: warm.secondary,

  // Semantic colors
  uploadImageBorder: warm.primary,
  tabsBackgroundColor: "transparent",
};
