import React from "react";
import styled, { withTheme } from "styled-components";
import Heading from "../../components/heading/heading";
import GoBack from "../../components/goBack/goBack";
import Input, { InputWrapper } from "../../components/input/input";
import Creatable from "react-select/creatable";
import {
  colourStyles,
  debounce,
  options,
  validateConfirmPass,
  validateEmail,
  validateFullnameRegister,
  validateNameRegister,
  validatePass,
  validateSocNetUrl,
  validateUsername,
} from "./utils";
import Portal from "../../components/portal/portal";
import Popup from "../../components/popup/popup";
import authService from "./authService";
import * as constants from "../../constants";
import { Container, Label } from "../../styling/globalStyling";
import { withRouter } from "react-router-dom";
import { setUserData } from "../profile/profileActions";
import { store } from "../../index";
import { redirectIfLoggedIn } from "../../utils";
import { socNetMap } from "../personalDetails/personalDetails";
import FSwitch from "../../components/Switch/FSwitch";
import { LoginButtonWrapper } from "./styledComponetns";
import { GoogleLogin } from "react-google-login";
import { connect } from "react-redux";

const GOOGLE_CLIENT_ID = process.env.REACT_APP_G_CLIENT_ID;

class Register extends React.Component {
  constructor(props) {
    super(props);
    const { ref } = this.props.match.params;
    this.state = {
      screen: 0,
      fullName: "",
      name: "",
      un: "",
      email: "",
      password: "",
      repeatPassword: "",
      usernameDesc: null,
      usernameIsValid: undefined,
      usernameSpininer: false,
      emailDesc: null,
      emailIsValid: undefined,
      emailSpinner: false,
      selectedOption: null,
      socialNetworks: socNetMap,
      openSocNetDialog: false,
      socNetInputKey: null,
      socNetUrl: "",
      signupError: "",
      referredBy: ref || null,
      isBusiness: false,
    };
  }

  screen1Ref = React.createRef();

  componentDidMount() {
    redirectIfLoggedIn(this.props.history);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.username !== nextProps.match.params.username) {
      this.setState({ referredBy: nextProps.match.params.username || null });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleUsrnameChange = (e) => {
    const username = e.target.value;
    if (!username) {
      this.setState({
        un: username,
        usernameDesc: null,
        usernameIsValid: undefined,
      });
      return;
    }
    this.setState({ un: username }, () => {
      const usernameIsValid = validateUsername.call(this);
      if (usernameIsValid === "success") {
        this.checkUsernameDebounced();
      } else {
        this.setState({ usernameIsValid });
      }
    });
  };

  checkUsernameDebounced = debounce(() => {
    const username = this.state.un;
    this.setState({ usernameSpininer: true });
    authService
      .checkUsername(username)
      .then((res) => {
        if (res.data) {
          this.setState({
            un: username,
            usernameDesc: <Available />,
            usernameIsValid: "success",
          });
        } else {
          this.setState({
            un: username,
            usernameDesc: <Unavailable />,
            usernameIsValid: "error",
          });
        }
      })
      .catch((e) => {
        console.log("e", e);
      });
  }, 2000);

  handleEmailChange = (e) => {
    const email = e.target.value;
    if (!email) {
      this.setState({ email, emailDesc: null, emailIsValid: undefined });
      return;
    }
    this.setState({ email }, () => {
      const emailIsValid = validateEmail.call(this);
      if (emailIsValid === "success") {
        this.checkEmailDebounced();
      } else {
        this.setState({ emailIsValid });
      }
    });
  };

  checkEmailDebounced = debounce(() => {
    const email = this.state.email;
    this.setState({ usernameSpininer: true });
    authService
      .checkEmail(email)
      .then((res) => {
        if (res.data) {
          this.setState({
            email,
            emailDesc: <Available />,
            emailIsValid: "success",
          });
        } else {
          this.setState({
            email,
            emailDesc: <Unavailable />,
            emailIsValid: "error",
          });
        }
      })
      .catch((e) => {
        console.log("e", e);
      });
  }, 2000);

  continue = () => {
    setTimeout(() => {
      this.screen1Ref.current?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 0);
    this.setState({ screen: 1 });
  };

  handleChangeSelect = (selectedOption) => {
    selectedOption.label =
      selectedOption.label.charAt(0).toUpperCase() +
      selectedOption.label.slice(1);
    this.setState({ selectedOption });
  };

  addSocNet = (key) => {
    this.setState({ openSocNetDialog: true, socNetInputKey: key });
  };

  getSocNetInput = () => {
    return (
      <Input
        wrapperstyle={{ width: "100%" }}
        className={"borderPrimary"}
        type={"text"}
        name={"socNetUrl"}
        value={this.state.socNetUrl}
        onChange={(e) => this.setState({ socNetUrl: e.target.value })}
        isvalid={validateSocNetUrl.call(this)}
      />
    );
  };

  onSocNetPopupSubmit = () => {
    if (this.state.socNetInputKey !== null) {
      const newState = { ...this.state };
      newState.socialNetworks[this.state.socNetInputKey].url =
        this.state.socNetUrl;
      this.setState({
        ...newState,
        socNetUrl: "",
        socNetInputKey: null,
        openSocNetDialog: false,
      });
    }
  };

  handleSuccessLogin = (res) => {
    localStorage.setItem(constants.SESSION_KEY, JSON.stringify(res.data));
    setUserData(store.dispatch, res.data.userProfile);
    if (this.props.location.state && this.props.location.state.from) {
      this.props.history.push(this.props.location.state.from.pathname);
    } else {
      this.props.history.push("/home");
    }
  };

  createAccount = () => {
    const {
      email,
      un,
      password,
      fullName,
      name,
      socialNetworks,
      selectedOption,
      referredBy,
      isBusiness,
    } = this.state;
    const data = {
      email,
      fullName,
      name,
      password,
      profession: selectedOption && selectedOption.label,
      socialNetworks: Object.keys(socialNetworks).map((key) => ({
        type: key,
        url: socialNetworks[key].url || "",
      })),
      username: un,
      referredBy,
      isBusiness,
    };
    this.setState({ signupError: "" });
    authService
      .signup(data)
      .then(this.handleSuccessLogin)
      .catch((e) => {
        this.setState({ signupError: e.message });
      });
  };

  onGoogleLoginSuccess = (res) => {
    this.setState({ signupError: "" });
    const data = {
      contactInformationType: "GOOGLE",
      value: res.tokenId,
    };
    authService
      .socialLogin(data)
      .then(this.handleSuccessLogin)
      .catch((e) => {
        this.setState({ signupError: e.message });
      });
  };

  onGoogleLoginFaluire = (res) => {
    this.setState({ signupError: "Failed to register using Google account." });
  };

  render() {
    const lang = this.props.lang;
    const selectStyles = colourStyles(this.props.theme);

    const disabledContinue = !(
      validateUsername.call(this) === "success" &&
      validateEmail.call(this) === "success" &&
      validatePass.call(this) === "success" &&
      validateConfirmPass.call(this) === "success"
    );
    const disabledRegister =
      disabledContinue ||
      (this.state.isBusiness && (!this.state.name || !this.state.fullName));
    const socialNetworks = this.state.socialNetworks;
    return (
      <Container>
        <Heading title={lang.registration} />
        <GoBack
          customAction={
            this.state.screen === 1 && (() => this.setState({ screen: 0 }))
          }
        />
        {this.state.screen === 0 ? (
          <Container className={"p016"}>
            <LoginButtonWrapper className="mt2 mb2">
              <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                buttonText="Continue with Google"
                onSuccess={this.onGoogleLoginSuccess}
                onFailure={this.onGoogleLoginFaluire}
                cookiePolicy="single_host_origin"
                isSignedIn={true}
              />
            </LoginButtonWrapper>
            <div className="text-size-xl fw500 text-center mt1 mb1">
              {lang.or}
            </div>
            <div>
              {lang.register_acceptance}{" "}
              <a
                href="https://bilet.r/termsofuse.html"
                target="_blank"
                className="terms"
                rel="noreferrer"
              >
                {lang.register_terms_of_use}
              </a>
            </div>
            <Input
              type={"email"}
              label={"email"}
              name={"email"}
              value={this.state.email}
              description={this.state.emailIsValid && this.state.emailDesc}
              onChange={this.handleEmailChange}
              isvalid={this.state.emailIsValid}
              autoComplete={"none"}
            />
            <Input
              type={"text"}
              label={lang.label_username}
              name={"un"}
              value={this.state.un}
              description={
                this.state.usernameIsValid && this.state.usernameDesc
              }
              onChange={this.handleUsrnameChange}
              isvalid={this.state.usernameIsValid}
              autoComplete={"none"}
            />

            <Input
              type={this.state.passEyeIcon ? "text" : "password"}
              label={lang.label_password}
              name={"password"}
              value={this.state.password}
              description={"Minimum 8 characters, 1 number"}
              onChange={this.handleChange}
              icon={
                this.state.passEyeIcon
                  ? "icon-password_visible"
                  : "icon-password_hidden"
              }
              iconClickHandler={() =>
                this.setState({ passEyeIcon: !this.state.passEyeIcon })
              }
              isvalid={validatePass.call(this)}
              autoComplete={"none"}
            />
            <Input
              className={"mb2"}
              type={this.state.repPassEyeIcon ? "text" : "password"}
              label={lang.label_verify_password}
              name={"repeatPassword"}
              value={this.state.repeatPassword}
              onChange={this.handleChange}
              icon={
                this.state.repPassEyeIcon
                  ? "icon-password_visible"
                  : "icon-password_hidden"
              }
              iconClickHandler={() =>
                this.setState({ repPassEyeIcon: !this.state.repPassEyeIcon })
              }
              isvalid={validateConfirmPass.call(this)}
              autoComplete={"none"}
            />

            <ContinueButton onClick={this.continue} disabled={disabledContinue}>
              {lang.continue}
            </ContinueButton>
          </Container>
        ) : (
          <Container className={"p016"} ref={this.screen1Ref}>
            <InputWrapper>
              <label>
                {lang.professional_acc}{" "}
                <span> ({lang.register_to_earn_money})</span>
              </label>
              <FSwitch
                checked={this.state.isBusiness}
                onChange={(value) => this.setState({ isBusiness: value })}
              />
            </InputWrapper>

            {this.state.isBusiness && (
              <>
                <Input
                  type={"text"}
                  label={lang.label_full_name}
                  name={"fullName"}
                  value={this.state.fullName}
                  onChange={this.handleChange}
                  isvalid={validateFullnameRegister.call(this)} // function
                  description={`(${lang.label_private})`}
                  descriptionPositionUp
                />
                <Input
                  type={"text"}
                  label={lang.label_stage_name}
                  name={"name"}
                  value={this.state.name}
                  onChange={this.handleChange}
                  isvalid={validateNameRegister.call(this)} // function
                  description={`(${lang.label_public})`}
                  descriptionPositionUp
                />
                <Label>Profession</Label>
                <Creatable
                  value={this.state.selectedOption}
                  onChange={this.handleChangeSelect}
                  styles={selectStyles}
                  options={options}
                  defaultMenuIsOpen={false}
                />

                <Label>Add url to your social networks</Label>
                <div className={"text-normal text-size-s mb1"}>
                  (Adding social networks increses your credibility)
                </div>
                {Object.keys(socialNetworks).map((key) => {
                  const sn = socialNetworks[key];
                  return (
                    <SocNet key={key}>
                      <img src={sn.icon} alt={sn.name} />
                      <span className={"title"}>{sn.url || sn.name}</span>
                      <i
                        className={"icon-add"}
                        onClick={() => this.addSocNet(key)}
                      />
                    </SocNet>
                  );
                })}
              </>
            )}

            <RegisterButton
              onClick={this.createAccount}
              disabled={disabledRegister}
            >
              {lang.register}
            </RegisterButton>
            <SignupError className={"text-normal text-size-s text-danger"}>
              {this.state.signupError}
            </SignupError>
          </Container>
        )}

        {this.state.openSocNetDialog && (
          <Portal>
            <Popup
              title={`${
                this.state.socialNetworks[this.state.socNetInputKey].name
              } url`}
              content={this.getSocNetInput()}
              cancelAction={() =>
                this.setState({
                  openSocNetDialog: false,
                  socNetInputIndex: null,
                  socNetUrl: "",
                })
              }
              submitAction={this.onSocNetPopupSubmit}
              submitDisabled={!this.state.socNetUrl}
            />
          </Portal>
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.home.language,
  };
}

export default withTheme(withRouter(connect(mapStateToProps)(Register)));

//
// const Container = styled.div`
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: flex-start;
// 	height: 100%;
// 	position: relative;
// 	overflow-y: scroll;
// 	-webkit-scrollbar {
//   	display: none;
// 	}
// 	-ms-overflow-style: none;
// 	::-webkit-scrollbar {
// 		width: 0px;
// 		background: transparent;
// 	}
// }
// `
const ContinueButton = styled.button`
  margin-top: auto;
  margin-bottom: 32px;
`;

const RegisterButton = styled.button`
  margin-top: 40px;
  margin-bottom: 12px;
`;

const SignupError = styled.div`
  text-align: center;
`;

export const SocNet = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  background: ${({ theme }) => theme.secondBackground};
  padding: 10px 20px 10px 12px;
  border-radius: 4px;
  position: relative;
  a {
    color: ${({ theme }) => theme.text};
    text-decoration: unset;
  }
  img {
    width: 24px;
    flex-basis: 24px;
  }

  .title {
    margin-left: 12px;
    flex-grow: 1;
    overflow: hidden;
  }

  i {
    cursor: pointer;
    margin-left: 12px;
  }
  &.small {
    height: 60px;
    width: 60px;
    display: inline-flex;
    margin-right: 15px;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    a {
      margin-left: 0;
    }
    img {
      height: 24px;
    }
  }
`;

const Available = () => <span className={"text-primary"}>Available</span>;
const Unavailable = () => <span className={"text-danger"}>Unavailable</span>;
