import React, { useState, useContext } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import stripeService from "./stripeService";
import "./payment.scss";
import GoBack from "../goBack/goBack";
import Heading from "../heading/heading";
import styled, { ThemeContext } from "styled-components";
import moment from "moment";
import { currencyMap } from "../../constants";
import { capitalizeFirstLetter } from "../../utils";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "../Spinner/Spinner";

export default function PaymentForm(props) {
  const history = useHistory();
  const { fangout = fMock, cardOptions } = props;
  const themeContext = useContext(ThemeContext);
  // const options = Object.assign({}, CARD_OPTIONS, {base: {...CARD_OPTIONS.base, color: themeContext.text}})
  const options = cardOptions || CARD_OPTIONS(themeContext.text);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [cElement, setCardElement] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const lang = useSelector((state) => state.home.language);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    setLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (!error) {
      try {
        const { id } = paymentMethod;
        const response = await stripeService.makePayment({
          paymentMethodId: id,
          fangoutId: fangout.id, //this should be done at backend for safety
        });

        // TODO check error handling
        if (response) {
          setLoading(false);
          setSuccess(true);
          setTimeout(() => {
            history.push("/requests/1/" + fangout.id);
          }, 500);
        } else {
          setError("Something gone wrong");
        }
      } catch (e) {
        setError(e.message);
        setLoading(false);
      }
    } else {
      setLoading(false);
      console.log("error", error);
    }
  };

  return (
    <div className={"p016"}>
      {loading && <Spinner fullscreen />}
      <GoBack />
      <Heading title={lang.payment_title} />
      {!success ? (
        <div className={"stripeContainer"}>
          {fangout && (
            <FangoutPaymentDetails>
              <div>{fangout.celebrity.name}</div>
              <div>
                {capitalizeFirstLetter(fangout.fangoutType.toLowerCase())}
              </div>
              <div>{moment(fangout.date).format("DD MMM YYYY  HH:mm")}</div>
              <div>
                {fangout.price}
                {currencyMap[fangout.currency]}
              </div>
            </FangoutPaymentDetails>
          )}
          <div className={"FormGroup"}>
            <FormRow>
              <CardElement options={options} onChange={setCardElement} />
            </FormRow>
          </div>
          <button
            disabled={
              !fangout || !stripe || !cElement || !cElement?.complete || loading
            }
            onClick={handleSubmit}
            className={"mt2"}
          >
            {lang.pay} {fangout?.price}
            {currencyMap[fangout?.currency]}
          </button>
          {error && <div className="text-danger">{error}</div>}
        </div>
      ) : (
        <FlexCenter>
          <h4>Paid successfully</h4>
        </FlexCenter>
      )}
    </div>
  );
}

export const CARD_OPTIONS = (color) => ({
  hidePostalCode: true,
  style: {
    iconStyle: "solid",
    base: {
      iconColor: "#c4f0ff",
      color: color,
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#87bbfd" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
});

export const FormRow = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 4px;
  padding-left: 10px;
  &.inline {
    flex-direction: row;
  }
`;
export const FangoutPaymentDetails = styled.div`
  margin-bottom: 1rem;
  align-self: flex-start;
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const fMock = {
  id: 88,
  billType: "SPLIT",
  celebrity: {
    id: 2,
    name: "Boza Zec",
    fullName: "Bozidar Zecevic",
    username: "boza",
    email: "bozazec@gmail.com",
    profession: "Computer Programmer",
    createdTime: "2020-09-30T08:39:35.926Z",
    socialNetworks: [
      {
        type: "yt",
        url: "",
      },
      {
        type: "onlyf",
        url: "",
      },
      {
        type: "tiktok",
        url: "",
      },
      {
        type: "tw",
        url: "",
      },
      {
        type: "ln",
        url: "https://www.linkedin.com/in/bozidar-zecevic/",
      },
      {
        type: "insta",
        url: "",
      },
      {
        type: "fb",
        url: "",
      },
    ],
    accountStatus: "ACTIVE",
    profilePhoto:
      "https://app.bilet.rs/photos/aea0e970-6d7d-42a6-bb57-9b888445e10f.jpg",
    sharePhoto:
      "https://app.bilet.rs/photos/f9f90af4-604c-4e72-a504-4edcfb9b3ca2.jpg",
    verified: "VERIFIED",
    bio: "CEO at Fangout LLC",
    allowOnlyVerified: false,
    activityCounter: 0,
    popularityCounter: 100000009,
    isBusiness: true,
    isPremium: "2023-05-15T23:07:15.206779Z",
  },
  fan: {
    id: 36,
    name: "Oliver Katic",
    fullName: "Oliver Katic ",
    username: "Oliver_Katic",
    email: "oliver.katic@gmail.com",
    profession: "Singer",
    createdTime: "2021-08-31T17:00:06.217Z",
    socialNetworks: [
      {
        type: "onlyf",
        url: "",
      },
      {
        type: "yt",
        url: "https://www.youtube.com/channel/UCD7-J_n4K8xloDwVfTSz5xQ",
      },
      {
        type: "insta",
        url: "https://www.instagram.com/oliver_katic/",
      },
      {
        type: "fb",
        url: "https://www.facebook.com/oliverkatic",
      },
      {
        type: "tiktok",
        url: "",
      },
      {
        type: "tw",
        url: "",
      },
      {
        type: "ln",
        url: "",
      },
    ],
    accountStatus: "ACTIVE",
    profilePhoto:
      "https://app.bilet.rs/photos/900c54a2-6bdf-4321-acf9-60d603351c6c.jpg",
    verified: "VERIFIED",
    allowOnlyVerified: false,
    activityCounter: 1000,
    popularityCounter: 100000011,
    isBusiness: false,
  },
  status: "COMPLETED",
  location: {
    title: "Belgrade, Centralna Srbija, Serbia",
    lat: 44.8131,
    lon: 20.46329,
    countryCode: "SRB",
    countryName: "Serbia",
    city: "Belgrade",
    state: "Centralna Srbija",
    hereId: "here:cm:namedplace:23832714",
  },
  currency: "EUR",
  date: "2022-01-22T19:00:00Z",
  fangoutType: "CASUAL",
  price: 500,
  numOfAttendees: 5,
  isCharity: false,
  createdByFan: false,
  duration: 2,
  sponsor: "Druzenje sa Milanom",
  sponsorImage: null,
  description:
    "Dodjite da se druzimo, popijemo kafu, i pomognemo malom Stefanu da ozdravi.",
};
