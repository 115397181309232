import languages from "../localization/languages";
// import english from '../localization/langFiles/english'
import srpski from "../localization/langFiles/srpski";

export const ActionTypes = {
  SET_LANGUAGE: "SET_LANGUAGE",
  SET_DARK_THEME: "SET_DARK_THEME",
  SET_IS_PULLABLE: "SET_IS_PULLABLE",
  SET_TOP_POPULAR: "SET_TOP_POPULAR",
  SET_TOP_ACTIVE: "SET_TOP_ACTIVE",
  SET_SUBSCRIPTIONS: "SET_SUBSCRIPTIONS",
};

const initialState = {
  language:
    (localStorage.getItem("language") &&
      languages[localStorage.getItem("language")]) ||
    srpski,
  darkTheme: localStorage.getItem("darkTheme") === "true",
  isPullable: true,
  topPopular: new Array(8).fill(null),
  topActive: new Array(8).fill(null),
  subscriptions: {
    users: {},
    locations: [],
  },
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_LANGUAGE:
      localStorage.setItem("language", action.lang);
      return Object.assign({}, state, { language: languages[action.lang] });
    case ActionTypes.SET_DARK_THEME:
      localStorage.setItem("darkTheme", action.darkTheme);
      return Object.assign({}, state, { darkTheme: action.darkTheme });
    case ActionTypes.SET_IS_PULLABLE:
      return Object.assign({}, state, { isPullable: action.payload });
    case ActionTypes.SET_TOP_POPULAR:
      return Object.assign({}, state, { topPopular: action.payload });
    case ActionTypes.SET_TOP_ACTIVE:
      return Object.assign({}, state, { topActive: action.payload });
    case ActionTypes.SET_SUBSCRIPTIONS:
      return Object.assign({}, state, { subscriptions: action.payload });
    default:
      return state;
  }
}

export function setLanguage(dispatch, lang) {
  dispatch({
    type: ActionTypes.SET_LANGUAGE,
    lang,
  });
}

export function setDarkTheme(dispatch, darkTheme) {
  dispatch({
    type: ActionTypes.SET_DARK_THEME,
    darkTheme,
  });
}
