import GoBack from "src/components/goBack/goBack";
import Heading from "src/components/heading/heading";
import { Button, Container } from "src/styling/globalStyling";
import { useLocation, useHistory } from "react-router-dom";
import { IShow } from "../createShow/createShow";
import Input from "src/components/input/input";
import { useState } from "react";

import style from "./GratisGenerator.module.scss";
import InputNew from "src/components/inputNew/InputNew";
import { useLang } from "src/components/useLang";
import { CurrencyInput } from "../createShow/styledComponents";
import { sanitizeCommaSeparatedValue } from "src/utils";
import Spinner from "src/components/Spinner/Spinner";
import config from "src/config";
import axios from "axios";
import toast from "react-hot-toast";

type PostData = {
  showId: number;
  ticketList: { numberOfTickets: number; ticketTypeId: number }[];
  receiverUserId?: number;
};

const GratisGenerator = () => {
  const location = useLocation();
  const history = useHistory();
  const lang = useLang();
  const show = location.state as IShow;
  const [ticketListObj, setTicketListObj] = useState({});
  const [userId, setUserId] = useState<number | string>("");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const setTicket = (id, value) => {
    const newObj = { ...ticketListObj, [id]: value };
    if (!value) {
      delete newObj[id];
    }
    setTicketListObj(newObj);
  };

  const createTickets = async () => {
    const data: PostData = {
      showId: show.id!,
      ticketList: Object.keys(ticketListObj).map((key) => ({
        numberOfTickets: ticketListObj[key],
        ticketTypeId: parseInt(key),
      })),
    };
    setError("");
    setLoading(true);
    try {
      if (userId) {
        // Create ticket for specific user
        data.receiverUserId = userId as number;
        const url = config.baseApi + "/v1/tickets/giveaway_for_user";
        await axios.post(url, data);
      } else {
        // Create ticket for admin
        const url = config.baseApi + "/v1/tickets/generate_giveaway";
        await axios.post(url, data);
      }
      toast.success("Tickets successfully created");
      setUserId("");
      setTicketListObj({});
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  if (!show) {
    history.push("/myshows");
  }

  return (
    <Container className="p016">
      <GoBack />
      <Heading title={"Gratis Tickets Creator"} />
      <h3 className="text-center mt0">{show?.showTitle}</h3>
      <Input
        value={userId}
        label={"User ID"}
        type={"number"}
        description={"If empty tickets goes to your account"}
        min={1}
        onChange={(e) => setUserId(e.target.value || null)}
        wrapperstyle={{ marginTop: 0, maxWidth: "220px" }}
      />
      <div className="mt2">
        <div className="text-bold text-center">Tickets</div>
        {show?.ticketTypes.map((t) => (
          <div key={t.id} className={style.ticketWrapper}>
            <div className={style.ticketName}>
              <>
                {t.title} ({t.ticketPrice} {show.currency})
              </>
            </div>
            <InputNew
              style={{ flexGrow: 1 }}
              type={"number"}
              label={lang.qty as string}
              name={"ticketsNumber"}
              value={ticketListObj?.[t.id!] || 0}
              onChange={(e) => {
                const sanitizedValue = sanitizeCommaSeparatedValue(
                  e.target.value
                );
                setTicket(t.id, parseInt(sanitizedValue));
              }}
              inputComponent={CurrencyInput}
              className={style.qtyInput}
            />
          </div>
        ))}
      </div>
      <Button
        type="button"
        className="mt2"
        onClick={createTickets}
        disabled={loading || !show || Object.keys(ticketListObj).length === 0}
      >
        Create
      </Button>
      {loading && <Spinner />}
      {error && <div className="text-danger text-center">{error}</div>}
    </Container>
  );
};

export default GratisGenerator;
