import visaCheck from '../../assets/images/paymentLogotips/visa-secure.jpg';
import mcIdCheck from '../../assets/images/paymentLogotips/mc_idcheck_vrt_rgb_pos.png';
import chipLogo from '../../assets/images/paymentLogotips/ChipCard LOGO 2021_rgb.png';
import dina from '../../assets/images/paymentLogotips/dina.jpg';
import visa from '../../assets/images/paymentLogotips/visa.png';
import maestro from '../../assets/images/paymentLogotips/maestro.png';
import mc from '../../assets/images/paymentLogotips/mc_acc_opt_70_1x.png';

import styles from './PaymentLogotips.module.scss';
import { Flex } from 'src/styling/globalStyling';
import { HTMLAttributes } from 'react';

const PaymentLogotips = (props: HTMLAttributes<HTMLDivElement>) => {
  const { className, ...rest } = props;
  return (
    <div className={`${styles.container} ${className}`} {...rest}>
      <Flex gap={'8px'}>
        <a
          href='https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html'
          target='_blank'
          rel='noreferrer'
        >
          <img className={styles.logo} src={visaCheck} alt='visa-secure' />
        </a>
        <a
          href='http://www.mastercard.com/rs/consumer/credit-cards.html'
          target='_blank'
          rel='noreferrer'
        >
          <img className={styles.logo} src={mcIdCheck} alt='mastercard' />
        </a>
      </Flex>
      <img className={styles.chip} src={chipLogo} alt='chipcard' />
      <img className={styles.logo} src={mc} alt='mastercard' />
      <img className={styles.logo} src={maestro} alt='maestro' />
      <img className={styles.logo} src={dina} alt='dina' />
      <img className={styles.logo} src={visa} alt='visa' />
    </div>
  );
};
export default PaymentLogotips;
