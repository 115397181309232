import React from "react";
import Heading from "../../components/heading/heading";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled, { useTheme, withTheme } from "styled-components";
import emptyAvatar from "../../assets/images/empty-avatar.jpg";
import Tabs from "../../components/tabs/tabs";
import {
  getUserData,
  saveShareProfilePhoto,
  saveUserDetails,
  setPTR,
  setUserData,
  uploadImage,
} from "./profileActions";
import {
  Button,
  Container,
  Flex,
  ImageEditIcon,
} from "../../styling/globalStyling";
import { forceLogout } from "../../App";
import Portal from "../../components/portal/portal";
import Popup from "../../components/popup/popup";
import profileService from "./profileService";
import ReactCrop from "react-image-crop";
import {
  CropContainer,
  ImageContainer,
} from "../personalDetails/personalDetails";
import { shareFeature } from "../../utils";
import config from "../../config";
import { Share, UserDetails } from "../publicProfile/publicProfile";
import ReactGA from "react-ga4";
import VerifyProfile from "../../components/verifyProfile/verifyProfile";
import { getImageOfHtml } from "../../components/htmlToImg/htmlToImg";
import fbShare from "../../assets/images/fb_share_white.png";
import Spinner from "../../components/Spinner/Spinner";
import Input from "../../components/input/input";
import moment from "moment";
import { dateFormat } from "../../constants";
import toast from "react-hot-toast";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openVerifyModal: false,
      openHelpModal: false,
      openHelpReferralModal: false,
      src: null,

      userData: {
        name: "",
        fullName: "",
        email: "",
        bio: "",
      },
      crop: {
        unit: "px",
        aspect: 1,
        width: 120,
        x: 30,
        y: 30,
      },
      croppedImageUrl: null,
      croppedImage: null,
      croppedImageB64: null,
      croppedImageApplied: false,
      removeAccountModal: false,
      isAdmin: false,
      showH2I: false,
      loading: false,
      openPremiumModal: "",
      activatePremium: "",
      premiumLoader: false,
      premiumError: "",
    };

    this.referalUrl = React.createRef();
    this.profilePhotoFileInput = React.createRef();

    this.htmlToImgRef = React.createRef();
  }

  componentDidMount() {
    ReactGA.send({ hitType: "pageview", page: this.props.location.pathname });

    // Disable PullToRefresh on this page
    this.props.setPTR(false);
    if (!this.props.userData.email) {
      this.props.getUserData().then((res) => {
        this.setState({
          userData: Object.assign({}, this.state.userData, res.data),
        });
      });
    } else {
      this.setState({ userData: this.props.userData });
    }

    const session = localStorage.getItem("session");
    if (session) {
      const s = JSON.parse(session);
      const permissions = s.permissions;
      if (permissions.includes("admin_endpoints")) {
        this.setState({ isAdmin: true });
      }
    }
  }

  componentWillUnmount() {
    this.props.setPTR(true);
  }

  getVerificationHelp = () => {
    return (
      <div className={"mt1 text-justify"}>
        <div className={"mb1"}>{this.props.lang.profile_help_message1}</div>
        <div className={"mb1"}>{this.props.lang.profile_help_message2}</div>
      </div>
    );
  };

  getReferralHelp = () => {
    return (
      <div className={"mt1 text-justify"}>
        <div className={"mb1"}>{this.props.lang.profile_help_ref_message1}</div>
        <div className={"mb1"}>{this.props.lang.profile_help_ref_message2}</div>
      </div>
    );
  };
  getRemoveAccountModalContent = () => {
    return (
      <div className={"mt1 text-justify"}>
        <div className={"mb1"}>
          {this.props.lang.profile_remove_account_message1}
        </div>
        <div className={"mb1"}>
          {this.props.lang.profile_remove_account_message2}
        </div>
      </div>
    );
  };

  handleProfilePhotoFile = (e) => {
    if (e.target.files.length) {
      const fileReader = new FileReader();
      fileReader.onloadend = () => this.setState({ src: fileReader.result });
      fileReader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(this.imageRef, crop);
      this.setState({ croppedImageUrl });
    }
  };

  getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;

    if (canvas.height > 0 && canvas.width > 0) {
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
      const reader = new FileReader();
      canvas.toBlob((blob) => {
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          this.setState({ croppedImageB64: reader.result });
          this.dataURLtoFile(reader.result, "cropped.jpg");
        };
      });
    }
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    this.setState({ croppedImage: croppedImage });
  }

  handleProfileUpladClick = () => {
    this.profilePhotoFileInput.current.click();
  };

  savePhoto = () => {
    this.setState({ loading: true });
    const pureBase64 = this.state.croppedImageB64.split(",")[1];
    uploadImage(pureBase64)
      .then(() => {
        this.props.getUserData().then((res) => {
          this.setState({ showH2I: true }, () => {
            this.htmlToImg().finally(() => {
              this.setState({
                croppedImageApplied: true,
                src: null,
                showH2I: false,
              });
            });
          });
        });
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Failed to save the photo.");
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  goToProfile = (e) => {
    e.stopPropagation();
    this.props.history.push("/public/" + this.props.userData.id);
  };

  removeAccount = () => {
    profileService.deleteProfile().then(() => {
      forceLogout();
      this.props.setUserData({});
    });
  };

  logout = () => {
    forceLogout();
    this.props.setUserData({});
  };

  contactSupport = () => {
    const mail = document.createElement("a");
    mail.href = "mailto:office@bilet.rs";
    mail.click();
  };

  htmlToImg = () => {
    return getImageOfHtml("#toImageProfilePhoto").then((photo) => {
      return saveShareProfilePhoto(photo);
    });
  };

  handleIsPremiumClick = (control) => {
    if (this.props.userData.isPremium) {
      this.props.history.push("/" + control);
    } else {
      this.setState({ openPremiumModal: control });
    }
  };

  activatePremium = () => {
    if (this.state.activatePremium) {
      this.setState({ premiumLoader: true, premiumError: "" });
      profileService
        .activatePremium(this.state.activatePremium)
        .then((res) => {
          this.props.setUserData(res.data);
          this.setState({ openPremiumModal: "" });
        })
        .catch((e) => {
          this.setState({ premiumError: e.message });
        })
        .finally(() => this.setState({ premiumLoader: false }));
    }
  };

  getPremiumModalContent = () => {
    const descriptionKey = `premium_desc_${this.state.openPremiumModal}`;

    const desc = this.props.lang[descriptionKey];

    return (
      <div className={"mt1 text-justify"}>
        <div className={"description mb2"}>{desc}</div>
        <Flex style={{ gap: 16 }}>
          <Input
            label={this.props.lang.premium_activate}
            type={"text"}
            name={"activate"}
            value={this.state.activatePremium}
            description={this.props.lang.premium_type_code}
            onChange={(e) => this.setState({ activatePremium: e.target.value })}
            autoComplete={"none"}
            inputstyle={{ border: `1px solid ${this.props.theme.primary}` }}
            wrapperstyle={{ marginTop: 0, flexGrow: 1 }}
          />
          <ActivatePremiumButton
            className={`fas fa-check`}
            isValid={this.state.activatePremium.length > 4}
            onClick={this.activatePremium}
          />
        </Flex>
        {this.state.premiumError && (
          <div className={"text-danger mt1"}>{this.state.premiumError}</div>
        )}
        {this.state.premiumLoader && <Spinner fullscreen />}
        <button
          className={"mt3 slim"}
          onClick={() => this.props.history.push("/premium")}
        >
          {this.props.lang.premium_buy_year}
        </button>
      </div>
    );
  };

  switchIsBusiness = (isBusiness) => {
    this.setState({ loading: true });
    const userData = this.state.userData;
    userData.isBusiness = isBusiness;
    delete userData.id;
    saveUserDetails(userData)
      .then((res) => {
        // this.props.setUserData(res.data)
        this.props.getUserData();
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const user = this.props.userData;
    const userIsUnverified = user.verified === "UNVERIFIED";
    const avatarSrc = this.state.userData.profilePhoto || emptyAvatar;
    const lang = this.props.lang;
    const photoUrl = this.state.croppedImageApplied
      ? this.state.croppedImageB64
      : avatarSrc;
    const shareReferralUrl = `${config.appUrl}/#/register/${this.props.userData.username}`;
    const { src, crop } = this.state;
    const htmlToImhHeight =
      this.htmlToImgRef?.current?.clientWidth / 1.91 + "px";

    return (
      <Container className={"profile pageContainer"}>
        {this.state.loading && <Spinner fullscreen />}
        {this.state.isAdmin && (
          <GoToAdmin>
            <Button
              className="small"
              onClick={() => this.props.history.push("/admin")}
            >
              admin
            </Button>
          </GoToAdmin>
        )}
        <ToImageProfilePhoto
          ref={this.htmlToImgRef}
          id={"toImageProfilePhoto"}
          style={{
            height: htmlToImhHeight,
            visibility: `${this.state.showH2I ? "visible" : ""}`,
          }}
        >
          <div className={"logoWrapper"}>
            <img className={"logoShare"} src={fbShare} />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", padding: "0 20px" }}
          >
            <UserDetails className={"p016"} style={{ marginBottom: 0 }}>
              <div className={"imgContainer relative"}>
                <img src={user.profilePhoto || emptyAvatar} alt={"avatar"} />
              </div>
              <div className={"username"}>
                <div className={"name"}>{user.name}</div>
                <div className={"profession"}>{user.profession}</div>
                <div className={"bio"}>{user.bio || ""}</div>
              </div>
            </UserDetails>
          </div>
        </ToImageProfilePhoto>
        <Heading title={lang.tabs_account} />
        <ProfileContainer className={"p016 mb200"}>
          <div className={"headerContent"}>
            {src ? (
              <CropContainer>
                <ReactCrop
                  src={src}
                  crop={crop}
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                  minHeight={80}
                  minWidth={80}
                  keepSelection={true}
                />
                <ImageEditIcon
                  className={"fas fa-save"}
                  onClick={this.savePhoto}
                />
                <ImageEditIcon
                  className={"fas fa-pencil-alt"}
                  onClick={this.handleProfileUpladClick}
                />
              </CropContainer>
            ) : (
              <ImageContainer>
                <img
                  className={"avatar"}
                  src={photoUrl}
                  alt={"avatar"}
                  onClick={this.goToProfile}
                />
                <ImageEditIcon
                  className={"fas fa-pencil-alt"}
                  onClick={this.handleProfileUpladClick}
                />
              </ImageContainer>
            )}
            <div className={"userDetails"}>
              <div className={"name"}>
                {user.name}{" "}
                {user.verified === "VERIFIED" && (
                  <i
                    title={"Verified"}
                    className={"fas fa-certificate text-primary"}
                  />
                )}
              </div>
              <div className={"username"}>
                @{user.username} ID: {user.id}
              </div>
              <div className={"verified"}>
                <Verified
                  onClick={() =>
                    userIsUnverified && this.setState({ openVerifyModal: true })
                  }
                  className={"clickable"}
                >
                  {user.verified === "UNVERIFIED" ? lang.verify : user.verified}
                </Verified>{" "}
                <i
                  onClick={() => this.setState({ openHelpModal: true })}
                  className={"fas fa-question-circle clickable"}
                />
              </div>
            </div>

            <Share style={{ marginTop: "2px" }}>
              <i
                className={"icon-share"}
                onClick={() =>
                  shareFeature(
                    `${config.baseApi + config.shareApi}/profiles/${user.id}`
                  )
                }
              />
            </Share>
          </div>

          <div className={"controlsWrapper mt2"}>
            <ProfileControl
              onClick={() => this.props.history.push("/personalDetails")}
              content={lang.profile_personal_details}
              icon={"icon-personal_details"}
            />

            {user.isBusiness ? (
              <ProfileControl
                onClick={() => this.props.history.push("/myshows")}
                content={lang.label_my_shows}
                icon={"fas fa-guitar"}
              />
            ) : null}

            <ProfileControl
              hasArrow
              onClick={() => this.props.history.push("/settings")}
              content={lang.profile_settings}
              icon={"fas fa-cog"}
            />
            <Hr />

            {!user.isBusiness ? (
              <ProfileControl
                style={{ fontSize: 12, height: 30 }}
                className={"small"}
                onClick={() => this.switchIsBusiness(true)}
                content={lang.switch_to_professional}
                icon={"fas fa-repeat"}
              />
            ) : (
              <ProfileControl
                style={{
                  fontSize: 12,
                  height: 30,
                  color: this.props.theme.gray,
                }}
                className={"small"}
                onClick={() => this.switchIsBusiness(false)}
                content={lang.switch_to_regular}
                icon={"fas fa-repeat"}
              />
            )}

            <ProfileControl
              className={"small"}
              style={{ fontSize: 12, height: 30 }}
              content={lang.profile_logout}
              icon={"icon-logout"}
              onClick={this.logout}
            />
            <ProfileControl
              className={"small"}
              style={{
                fontSize: 12,
                color: this.props.theme.primary,
                height: 30,
              }}
              content={lang.support}
              icon={"fas fa-life-ring"}
              onClick={this.contactSupport}
            />
            {/* <ProfileControl
              className={'small'}
              style={{ fontSize: 12, color: this.props.theme.gray, height: 30 }}
              content={lang.profile_remove_account}
              icon={'icon-remove'}
              onClick={() => this.setState({ openRemoveAccountModal: true })}
            /> */}
            <Version>v1.2.3</Version>
          </div>
        </ProfileContainer>
        <Tabs active={4} />
        <input
          type="file"
          style={{ display: "none" }}
          ref={this.profilePhotoFileInput}
          onChange={this.handleProfilePhotoFile}
          accept="image/*"
        />
        <VerifyProfile
          openVerifyModal={this.state.openVerifyModal}
          closeModal={() => this.setState({ openVerifyModal: false })}
        />
        {this.state.openPremiumModal && (
          <Portal>
            <Popup
              title={lang.premium_feature}
              content={this.getPremiumModalContent()}
              cancelAction={() => this.setState({ openPremiumModal: "" })}
              cancelText={lang.close}
              trackingTitle={"buy_premium_modal"}
            />
          </Portal>
        )}
        {this.state.openHelpModal && (
          <Portal>
            <Popup
              title={lang.profile_help_title}
              content={this.getVerificationHelp()}
              submitAction={
                userIsUnverified
                  ? () =>
                      this.setState({
                        openHelpModal: false,
                        openVerifyModal: true,
                      })
                  : null
              }
              submitText={userIsUnverified && lang.verify}
              cancelAction={() => this.setState({ openHelpModal: false })}
              cancelText={lang.close}
              trackingTitle={"help_profile_verify"}
            />
          </Portal>
        )}
        {this.state.openHelpReferralModal && (
          <Portal>
            <Popup
              title={lang.profile_help_ref_title}
              content={this.getReferralHelp()}
              cancelAction={() =>
                this.setState({ openHelpReferralModal: false })
              }
              cancelText={lang.ok}
              trackingTitle={"help_profile_referral"}
            />
          </Portal>
        )}
        {this.state.openRemoveAccountModal && (
          <Portal>
            <Popup
              title={lang.profile_remove_account}
              content={this.getRemoveAccountModalContent()}
              cancelAction={() =>
                this.setState({ openRemoveAccountModal: false })
              }
              submitAction={this.removeAccount}
              submitText={lang.remove}
              cancelText={lang.cancel}
              trackingTitle={"remove_account_modal"}
            />
          </Portal>
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    userData: state.profile.userData,
    lang: state.home.language,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserData: () => getUserData(dispatch),
    setUserData: (data) => setUserData(dispatch, data),
    setPTR: (state) => setPTR(dispatch, state),
  };
}

export default withRouter(
  withTheme(connect(mapStateToProps, mapDispatchToProps)(Profile))
);

const Version = styled.div`
  text-align: right;
  color: ${({ theme }) => theme.gray};
  font-size: 12px;
  font-style: italic;
`;
const ProfileControlEl = styled.div`
  // margin-top: 6px;
  // background-color: ${({ theme }) => theme.secondBackground};
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.secondBackground};
    // color: ${({ theme }) => theme.contrastText};
    opacity: 1;
    &:active {
      opacity: 0.8;
    }
  }
  .firstIcon {
    display: flex;
    align-items: center;
    margin-left: 0;
    font-size: 16px;
  }
  .content {
    margin-left: 10px;
    position: relative;
  }
  .arrow {
    display: flex;
    align-items: center;
    margin: 0 8px 0 auto;
    font-size: 12px;
  }

  &.small {
    i.firstIcon {
      font-size: 14px;
    }
  }
`;
const ProfileControl = (props) => {
  const theme = useTheme();
  const { onClick, isPremium, isUserPremium } = props;
  return (
    <ProfileControlEl onClick={onClick} {...props}>
      <i className={`firstIcon ${props.icon}`} />
      <div className={"content"}>
        {props.content}
        {isPremium && (
          <i
            style={{ color: theme.yellow, fontSize: "18px" }}
            className={"fas fa-star"}
          />
        )}
        {!isUserPremium && isPremium && (
          <i
            style={{
              color: theme.secondBackground,
              fontSize: "9px",
              position: "absolute",
              right: "6px",
              top: "5px",
            }}
            className={"fas fa-lock"}
          />
        )}
      </div>
      {props.hasArrow && <i className={`arrow icon-arrow-right`} />}
    </ProfileControlEl>
  );
};

export const ProfileContainer = styled(Container)`
  align-items: center;
  padding-bottom: 130px;

  .headerContent {
    display: flex;
    align-self: flex-start;
    width: 100%;
  }
  .userDetails {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .avatar {
    width: 100%;
    border-radius: 10px;
  }
  .name {
    font-size: 16px;
    font-weight: 600;
  }
  .username,
  .fullName,
  .verified {
    font-size: 14px;
    font-weight: 500;
  }
  i {
    font-size: 16px;
    margin-left: 10px;
    cursor: pointer;
  }
  .referal {
    width: 100%;
    margin-top: 12px;
    font-size: 14px;
    font-weight: 500;
    align-self: flex-start;
    // margin-bottom: -24px;
  }
  .url {
    // margin-left: 15px;
    font-size: 14px;
    word-break: break-all;
    color: ${({ theme }) => theme.primary};
    &:active {
      opacity: 0.8;
    }
  }
  .controlsWrapper {
    margin-top: 1.5rem;
    width: 100%;
  }
`;

export const Hr = styled.hr`
  border: none;
  height: 1px;
  min-height: 1px;
  background-color: ${({ theme }) => theme.lightGray};
`;

const Verified = styled.span`
  color: ${(props) =>
    props.children &&
    (props.children === "PENDING" ? props.theme.yellow : props.theme.primary)};
  font-size: 12px;
  font-weight: 600;
  //text-transform: uppercase;
  margin-top: 12px;
  margin-left: 5px;
  cursor: pointer;
`;

const ToImageProfilePhoto = styled.div`
  background: ${({ theme }) => theme.background};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  visibility: hidden;
  .logoWrapper {
    padding: 20px 60px 0 60px;
  }
  img.logoShare {
    height: 50px;
  }
`;

const GoToAdmin = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
`;

export const Helper = styled.i`
  display: inline;
  &:active {
    opacity: 0.8;
  }
`;

const ActivatePremiumButton = styled.i`
  margin-top: 25px;
  font-size: 28px;
  background: ${({ isValid }) => (isValid ? "#ffffff33" : "#ffffff11")};
  cursor: ${({ isValid }) => (isValid ? "pointer" : "not-allowed")};
  padding: 7px 9px;
  border-radius: 50%;
  color: ${({ isValid }) => (isValid ? "#fff" : "#444")};
  &:active {
    background: ${({ isValid }) => (isValid ? "#ffffff55" : "#ffffff11")};
  }
`;
