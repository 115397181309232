import styled from "styled-components";
import logo from "../../assets/images/logo 16x16.svg";

const TitleWrapper = styled.div`
  display: flex;
  img {
    width: 40px;
    background-color: #000;
    border-radius: 10px;
  }
`;
const LogoTitle = () => (
  <TitleWrapper>
    <img src={logo} alt={"logo"} />
  </TitleWrapper>
);

export default LogoTitle;
