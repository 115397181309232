import React from "react";
import { Container } from "../../styling/globalStyling";
import Heading from "../../components/heading/heading";
import GoBack from "../../components/goBack/goBack";
import styled, { withTheme } from "styled-components";
import "./settings.scss";
import Select from "react-select";
import { colourStyles } from "../Login/utils";
import { setLanguage, setDarkTheme } from "../homeReducer";
import { connect } from "react-redux";
import notificationsService from "../../notificationsService";
import { subscribeToFirebase } from "../../utils";
import ReactGA from "react-ga4";
import FSwitch from "../../components/Switch/FSwitch";

class Settings extends React.Component {
  constructor(props) {
    super(props);

    const pushNotif = localStorage.getItem("notification");
    this.state = {
      pushNotification: !!pushNotif,
      language: langMap[this.props.lang.id],
      // currency: currency[0]
    };
  }

  componentDidMount() {
    ReactGA.send({ hitType: "pageview", page: this.props.location.pathname });
  }

  handleNotificationChange = (e) => {
    if (this.state.pushNotification) {
      try {
        const session = localStorage.getItem("session");
        if (session) {
          notificationsService.unsubscribeUser(JSON.parse(session).accessToken);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      subscribeToFirebase();
    }
    this.setState({ pushNotification: !this.state.pushNotification });
  };

  enableDarkMode = (checked) => {
    this.props.setDarkTheme(checked);
  };

  setLanguage = (lang) => {
    this.setState({ language: langMap[lang.value] });
    this.props.setLanguage(lang.value);
  };

  // setCurrency = c => {
  // 	this.setState({currency: c})
  // }

  render() {
    const selectStyles = colourStyles(this.props.theme);
    return (
      <Container className={"settings"}>
        <Heading title={this.props.lang.settings_title} />
        <GoBack />
        <Container className={"p016 mt2"}>
          <SettingsOption>
            <span>{this.props.lang.toggle_push_notifications}</span>
            <FSwitch
              onChange={this.handleNotificationChange}
              checked={this.state.pushNotification}
            />
          </SettingsOption>

          <SettingsOption>
            <span>{this.props.lang.settings_enable_dark_mode}</span>
            <FSwitch
              onChange={this.enableDarkMode}
              checked={this.props.darkTheme}
            />
          </SettingsOption>

          <SettingsOption>
            <span>{this.props.lang.settings_language}</span>
            <Select
              className={"select"}
              styles={selectStyles}
              options={languages}
              value={this.state.language}
              onChange={this.setLanguage}
            />
          </SettingsOption>
          {/*<SettingsOption>*/}
          {/*	<span>{this.props.lang.settings_currency}</span>*/}
          {/*	<Select*/}
          {/*		value={this.state.currency}*/}
          {/*		className={'select'}*/}
          {/*		styles={selectStyles}*/}
          {/*		options={currency}*/}
          {/*		onChange={this.setCurrency}*/}
          {/*	/>*/}
          {/*</SettingsOption>*/}
        </Container>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    darkTheme: state.home.darkTheme,
    lang: state.home.language,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setDarkTheme: (darkTheme) => setDarkTheme(dispatch, darkTheme),
    setLanguage: (lang) => setLanguage(dispatch, lang),
  };
}

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
);

export const SettingsOption = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.secondBackground};
  margin-bottom: 1rem;
  padding: 5px 10px;
  align-items: center;
  border-radius: 4px;
`;

const langMap = {
  EN: { value: "EN", label: "English" },
  SR: { value: "SR", label: "Srpski" },
  MK: { value: "MK", label: "Македонски" },
};
const languages = [
  { value: "EN", label: "English" },
  { value: "SR", label: "Srpski" },
  { value: "MK", label: "Македонски" },
];
