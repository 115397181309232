import GoBack from 'src/components/goBack/goBack';
import Heading from 'src/components/heading/heading';
import PaymentLogotips from 'src/components/paymentLogotips';
import { Container, TextContainer } from 'src/styling/globalStyling';

const PaymentMethods = () => {
  return (
    <Container>
      <GoBack />
      <Heading title='Način plaćanja' />
      <TextContainer>
        <b>Plaćanje platnom karticom</b>
        <p>
          Sva plaćanja biće izvršena u lokalnoj valuti Republike Srbije – dinar
          (RSD). Za informativni prikaz cena u drugim valutama koristi se
          srednji kurs Narodne Banke Srbije. Iznos za koji će biti zadužena Vaša
          platna kartica biće izražen u Vašoj lokalnoj valuti kroz konverziju u
          istu po kursu koji koriste kartičarske organizacije, a koji nama u
          trenutku transakcije ne može biti poznat. Kao rezultat ove konverzije
          postoji mogućnost neznatne razlike od originalne cene navedene na
          našem sajtu. Hvala Vam na razumevanju.
        </p>
        <p>
          U ime Bilet Solutions DOO obavezujemo se da ćemo čuvati privatnost
          svih naših kupaca. Prikupljamo samo neophodne, osnovne podatke o
          kupcima/ korisnicima i podatke neophodne za poslovanje i informisanje
          korisnika u skladu sa dobrim poslovnim običajima i u cilju pružanja
          kvalitetne usluge. Dajemo kupcima mogućnost izbora uključujući
          mogućnost odluke da li žele ili ne da se izbrišu sa mailing lista koje
          se koriste za marketinške kampanje. Svi podaci o korisnicima/kupcima
          se strogo čuvaju i dostupni su samo zaposlenima kojima su ti podaci
          nužni za obavljanje posla. Svi zaposleni u Bilet Solutions DOO (i
          poslovni partneri) odgovorni su za poštovanje načela zaštite
          privatnosti.
        </p>
        <p>
          Plaćanje se odvija na stranici Payten provajdera platnih usluga.
          Sigurnost podataka prilikom kupovine, garantuje procesor platnih
          kartica, pa se tako kompletni proces naplate obavlja u sigurnom
          okruženju. Niti jednog trenutka podaci o platnoj kartici nisu dostupni
          našem sistemu.
        </p>
        <p>
          U slučaju vraćanja robe i povraćaja sredstava kupcu koji je prethodno
          platio nekom od platnih kartica, delimično ili u celosti, a bez obzira
          na razlog vraćanja, Bilet Solutions DOO je u obavezi da povraćaj vrši
          isključivo preko VISA, EC/MC i Maestro metoda plaćanja, što znači da
          će banka na zahtev prodavca obaviti povraćaj sredstava na račun
          korisnika kartice.
        </p>
        <PaymentLogotips className={'mt3'} />
      </TextContainer>
    </Container>
  );
};

export default PaymentMethods;
