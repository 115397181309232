import { useEffect } from 'react';
import Tabs from '../../components/tabs/tabs';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setShows } from './fangoutsActions';
import { filterShows } from '../../utils';
import ReactGA from 'react-ga4';
import ShowItem from '../showItem/showItem';
import logo from '../../assets/images/logo 16x16.svg';
import PaymentLogotips from 'src/components/paymentLogotips';

const Fangouts = ({ history, location, theme }) => {
  const dispatch = useDispatch();
  const shows = useSelector((state) => state.fangouts.shows);
  const lang = useSelector((state) => state.home.language);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });

    if (shows.length === 0) {
      setShows(dispatch);
    }
  }, [location.pathname, shows.length, dispatch]);

  return (
    <HomeContainer id={'homePage'}>
      <MainHeadingLine>
        <img className='logo' src={logo} alt='bliet.rs' />
        <div>BILET.RS</div>
        {process.env.REACT_APP_ENV === 'development' && <span>Dev</span>}
      </MainHeadingLine>

      <div className={'fangoutsContainer noscroll p016'}>
        {shows?.filter(filterShows).length > 0 && (
          <MostWanted>
            <div className={'heading'}>{lang.label_shows}</div>
            {shows.filter(filterShows).map((s) => (
              <ShowItem key={s.id} show={s} />
            ))}
          </MostWanted>
        )}
      </div>
      <PaymentLogotips />
      <Tabs active={0} lang={lang} />
    </HomeContainer>
  );
};

export default Fangouts;

const HomeContainer = styled.div`
  overflow: hidden;
  flex: 1 1;
  display: flex;
  flex-direction: column;

  .fangoutsContainer {
    padding-top: 16px;
    overflow: scroll;
    padding-bottom: 200px;
  }

  .heading {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
  }
`;

const MainHeadingLine = styled.div`
  display: flex;
  padding: 15px 16px 14px;
  justify-content: center;
  align-items: center;
  height: 56px;
  gap: 16px;
  padding-right: 6px;
  position: relative;
  font-weight: 500;
  font-size: 24px;

  img.logo {
    position: absolute;
    left: 8px;
    top: 8px;
    background-color: #000;
    border-radius: 10px;
    width: 40px;
  }

  .icon-logo {
    font-size: 120px;
    margin-top: -42px;
  }
`;

const MostWanted = styled.div`
  padding: 5px 0;
  margin-bottom: 16px;
  border-radius: 16px;

  .heading {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 12px;
  }
`;
