import React from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";

import GoBack from "../../components/goBack/goBack";
import { Container } from "../../styling/globalStyling";
import Heading from "../../components/heading/heading";
import { Content } from "../createFangout/createFangout";
import config from "../../config";
import Spinner from "../../components/Spinner/Spinner";
import styled, { useTheme, withTheme } from "styled-components";
import moment from "moment";
import { IShow } from "../createShow/createShow";
import { useLang } from "../../components/useLang";
import { ShowItemWrapper } from "../showItem/showItem";
import { AddButton } from "../../components/tabs/tabs";
import { TopFab } from "../myFangouts/MyFangouts";
import { getShowTypeObject } from "../../constants";
import Expandable from "../../components/expandable/Expandable";

export const dateTimeFormat = "DD MMM YYYY HH:mm";

const filterValidShows = (show: IShow) => {
  if (show.isDraft) return true;
  const eventDate = moment(show.endDate).add(1, "d");
  return moment().isBefore(eventDate);
};
const filterHistoryShows = (show: IShow) => {
  const eventDate = moment(show.endDate).add(1, "d");
  return moment().isAfter(eventDate);
};
const sortShows = (a: IShow, b: IShow) => {
  const timeA = moment(a.showDate);
  const timeB = moment(b.showDate);
  if (timeA.isBefore(timeB)) {
    return 1;
  } else if (timeA.isAfter(timeB)) {
    return -1;
  }
  return 0;
};

const fetchMyShows = async () => {
  const url = config.baseApi + "/v1/shows";
  return axios.get(url);
};

const MyShows = () => {
  const history = useHistory();
  const lang = useLang();

  const { isLoading: isLoadingMyShows, data: myShowsData } = useQuery(
    ["myshows"],
    fetchMyShows
  );

  return (
    <Container>
      <GoBack />
      <Heading title={lang.label_my_shows} />
      <TopFab onClick={() => history.push("/newshow")}>
        <AddShow />
      </TopFab>
      <Content className={`p016 noscroll`}>
        {isLoadingMyShows && <Spinner className={"mt1"} />}
        {myShowsData &&
          myShowsData.data
            .filter(filterValidShows)
            .sort(sortShows)
            .map((s) => <ShowItem key={s.id} s={s} />)}
        {myShowsData && (
          <Expandable title={"History"}>
            {myShowsData?.data
              .filter(filterHistoryShows)
              .sort(sortShows)
              .map((s) => (
                <ShowItem key={s.id} s={s} />
              ))}
          </Expandable>
        )}
      </Content>
    </Container>
  );
};

export default withTheme(MyShows);

const ShowItem = ({ s }) => {
  const history = useHistory();
  const theme = useTheme();
  const lang = useLang();

  const showTypeMap = getShowTypeObject(lang);

  const openInvalidator = (e, s: IShow) => {
    e.stopPropagation();
    history.push(`/invalidator/${s.showCode}`);
  };

  const openReporter = (e, s: IShow) => {
    e.stopPropagation();
    history.push(`/reporter/${s.showCode}`);
  };

  const goToShow = (s: IShow) => {
    if (s.isDraft) {
      history.push(`/editshow/${s.id}`);
    } else {
      history.push(`/myshows/${s.id}`);
    }
  };

  return (
    <ShowItemWrapper onClick={() => goToShow(s)}>
      <div className={"flex gap15"}>
        <div className="details">
          <div className="heading flex space-between">
            <div className={"title"}>
              {s.showTitle}{" "}
              {s.isDraft && (
                <span style={{ color: theme.yellow }}>(Draft)</span>
              )}
            </div>
            <div className={"mr5"}>{showTypeMap[s.showType].label}</div>
          </div>
          <div>{moment(s.showDate).format(dateTimeFormat)}</div>
          <div className="fit-content">{s?.location?.title}</div>
          {!s.isDraft && (
            <div>
              <SoftButton onClick={(e) => openInvalidator(e, s)}>
                {lang.invalidator}
              </SoftButton>
              <SoftButton onClick={(e) => openReporter(e, s)}>
                {lang.reporter}
              </SoftButton>
            </div>
          )}
        </div>
        <img src={s.eventImage} />
      </div>
    </ShowItemWrapper>
  );
};

export const SoftButton = styled.button`
  padding: 2px 10px;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 500;
  width: auto;
  color: ${({ theme }) => theme.dark};
  border: 1px solid ${({ theme }) => theme.middleGray};
  background: transparent;
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    color: ${({ theme }) => theme.contastBackground};
    border-color: ${({ theme }) => theme.contastBackground};
  }

  &:active {
    color: ${({ theme }) => theme.middleGray};
    border-color: ${({ theme }) => theme.middleGray};
  }
`;

// eslint-disable-next-line react/jsx-no-undef
const AddShow = () => (
  <AddButton className={"show"}>
    <i className={"icon-add"} />
    <i className={"fas fa-guitar"} />
  </AddButton>
);
