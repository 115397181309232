export const SESSION_KEY = "session";

export const TICKET_FEE = 4; //%

export const typeValues = [
  { label: "Performance", value: "PERFORMANCE" },
  { label: "Casual", value: "CASUAL" },
  { label: "Education", value: "EDUCATION" },
];

export const getTypeValues = (lang) => [
  { label: lang.PERFORMANCE, value: "PERFORMANCE" },
  { label: lang.CASUAL, value: "CASUAL" },
  { label: lang.EDUCATION, value: "EDUCATION" },
];
export const getTypeObject = (lang) => ({
  PERFORMANCE: { label: lang.PERFORMANCE, value: "PERFORMANCE" },
  CASUAL: { label: lang.CASUAL, value: "CASUAL" },
  EDUCATION: { label: lang.EDUCATION, value: "EDUCATION" },
});

export const SHOW = "SHOW";
export const ONLINE_SHOW = "ONLINE_SHOW";
export const HYBRID_SHOW = "HYBRID_SHOW";

export const getShowTypeValues = (lang) => [
  { label: lang.SHOW, value: SHOW },
  { label: lang.ONLINE_SHOW, value: ONLINE_SHOW },
  { label: lang.HYBRID_SHOW, value: HYBRID_SHOW },
];
export const getShowTypeObject = (lang) => ({
  SHOW: { label: `${lang.live} ${lang.SHOW}`, value: SHOW },
  ONLINE_SHOW: { label: lang.ONLINE_SHOW, value: ONLINE_SHOW },
  HYBRID_SHOW: { label: lang.HYBRID_SHOW, value: HYBRID_SHOW },
});

export const getShowTypeDescriptions = (lang) => ({
  SHOW: lang.show_desc,
  ONLINE_SHOW: lang.online_desc,
  HYBRID_SHOW: lang.hybrid_desc,
});

export const getShowAgeRestriction = (lang) => [
  { label: lang.show_no_restriction, value: 0 },
  { label: 12, value: 12 },
  { label: 13, value: 13 },
  { label: 14, value: 14 },
  { label: 15, value: 15 },
  { label: 16, value: 16 },
  { label: 17, value: 17 },
  { label: 18, value: 18 },
  { label: 19, value: 19 },
  { label: 20, value: 20 },
  { label: 21, value: 21 },
];

export const typeDescriptions = {
  CASUAL: "(Drink, dinner, walk, tennis match...)",
  EDUCATION: "(Knowledge transfer of any kind)",
  PERFORMANCE: "(Singing, acting, dancing, standup...)",
};
export const getTypeDescriptions = (lang) => ({
  CASUAL: lang.casual_description,
  EDUCATION: lang.education_description,
  PERFORMANCE: lang.performance_description,
});
export const billValues = (lang) => [
  { label: lang.SPLIT, value: "SPLIT" },
  { label: lang.FAN_PAYS, value: "FAN_PAYS" },
  { label: lang.CELEBRITY_PAYS, value: "CELEBRITY_PAYS" },
];
export const billObject = (lang) => ({
  SPLIT: { label: lang.SPLIT, value: "SPLIT" },
  FAN_PAYS: { label: lang.FAN_PAYS, value: "FAN_PAYS" },
  CELEBRITY_PAYS: { label: lang.CELEBRITY_PAYS, value: "CELEBRITY_PAYS" },
});
export const dateFormat = "yyyy-MM-DD";
export const timeFormat = "HH:mm";
export const availDateFormat = "DD MMM YYYY";

export const typeMap = (lang) => ({
  CASUAL: lang.CASUAL,
  EDUCATION: lang.EDUCATION,
  PERFORMANCE: lang.PERFORMANCE,
});

export const billMap = (lang) => ({
  FAN_PAYS: lang.FAN_PAYS,
  SPLIT: lang.SPLIT,
  CELEBRITY_PAYS: lang.CELEBRITY_PAYS,
});

export const currencyMap = {
  EUR: "€",
  USD: "$",
  RSD: "RSD",
};

export const currencySelectOptions = [
  { label: "RSD", value: "RSD" },
  { label: "EUR", value: "EUR" },
  { label: "USD", value: "USD" },
];
export const currencySelectObject = {
  RSD: { label: "RSD", value: "RSD" },
  EUR: { label: "EUR", value: "EUR" },
  USD: { label: "USD", value: "USD" },
};

export const fangoutStatusMap = {
  AVAILABLE: "Available",
  PENDING: "Pending",
  CONFIRMED: "Confirmed",
  CANCELLED: "Cancelled",
  COMPLETED: "Completed",
};

export const verifiedStatusMap = {
  UNVERIFIED: "Unverified",
  PENDING: "Pending",
  VERIFIED: "Verified",
};
export const foutStatus = {
  AVAILABLE: "AVAILABLE",
  PENDING: "PENDING",
  CONFIRMED: "CONFIRMED",
  CANCELLED: "CANCELLED",
  COMPLETED: "COMPLETED",
  DISPUTE: "DISPUTE",
};
